import { defineStore } from 'pinia'
import mitt from 'mitt'

import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { api, preferences } from '@/services'
import { AppIdentity } from '@/plugins/app-identity'

export const useAppStore = defineStore('app', {
    state: () => {
        return {
            nativeIdentity: null,
            id: null,
            appVersion: import.meta.env.VITE_APP_VERSION ?? '0.0.1',
            initialUrlSearch: null,
            launched: false,
            mockData: false,
            draftVersion: null,
            info: null,
            events: mitt(),
        }
    },
    getters: {
        appId: (state) => {
            return state.id
        },
    },
    actions: {
        async loadNativeIdentity() {
            if (Capacitor.isNativePlatform()) {
                // Get environment variables from native plugin
                this.nativeIdentity = await AppIdentity.getEnvironment()
            }
        },
        async getAppId() {
            return this.nativeIdentity?.appId
                // For the JS bundled with the app the following value will be the app ID
                // However, after hot updating the value will be null. This is because live update code is shared across all apps
                ?? import.meta.env.VITE_APP_ID
        },
        async init(appIdOverride = null, mockData = false, version = null) {
            // Specify whether the app should send headers requesting mock data
            this.mockData = mockData

            await this.loadNativeIdentity()

            // Override the app id built into the source
            this.id = appIdOverride ?? await this.getAppId()

            if (!this.id) {
                throw new Error('No app id specified')
            }

            // Set the prefix for preference keys
            await preferences.setPrefix(this.id)

            // Set draft version if provided
            version = version || await preferences.getItem('draftVersion')
            if (version === 'reset') {
                this.resetDraftVersion()
            } else if (version) {
                this.setDraftVersion(version)
            }

            // Load in app info
            this.info = Capacitor.isNativePlatform()
                ? await App.getInfo()
                : {}

            return this.id
        },
        async checkVersion() {
            return api.get(`/${this.id}/updates/check`, {
                params: {
                    store_version: this.info.version,
                    native_version: this.nativeIdentity?.appVersion,
                    js_version: this.appVersion,
                },
            })
                .then(({ data }) => {
                    console.log('current version:', this.appVersion)
                    console.log('new version:', data.version)
                    return data
                })
        },
        markLaunched() {
            this.launched = true
        },
        restart() {
            const url = (this.initialUrlSearch || '').replace(/^\?/, '')
            window.location.replace(`?${url}`)
        },
        async resetStorage() {
            return preferences.removeAll(true)
        },
        enableMockData() {
            this.mockData = true
            this.events.emit('mockDataEnabled', true)
        },
        disableMockData() {
            // Ensure the dashboard is reloaded etc. so that the user cannot bypass auth on startup
            this.restart()
        },
        resetDraftVersion() {
            this.draftVersion = null
            preferences.removeItem('draftVersion')
        },
        setDraftVersion(version) {
            this.draftVersion = version
            return preferences.setItem({ key: 'draftVersion', value: version })
        },
        setInitialUrlSearch(search) {
            this.initialUrlSearch = search
        },
    },
})
