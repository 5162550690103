<template>
    <div class="fixed inset-0 bg-white z-[9999]">
        <div class="font-medium text-md flex items-center justify-between px-3 min-h-header z-10 overflow-hidden pt-[var(--beyond-safe-area-inset-top)] border-b border-b-transparent transition-colors duration-300 absolute inset-x-0">
            <div class="w-[50px]">
                <div class="w-[38px] h-[38px] flex items-center justify-center rounded-full bg-white" @click="close">
                    <PhIcon v-if="isIos" icon="caret-left" weight="regular" :size="24" />
                    <PhIcon v-else icon="arrow-left" weight="regular" :size="24" />
                </div>
            </div>

            <span
                class="flex-1 truncate text-gray-900 font-medium"
                :class="isIos ? 'text-center' : 'text-left'"
            >
                {{currentIndex + 1}}/{{props.gallery.images.length}}
            </span>

            <div v-if="showContextMenu" :id="modalUuid" class="w-[38px] h-[38px] flex items-center justify-center rounded-full" data-cy="image-context-menu">
                <PhIcon icon="dots-three-vertical" weight="bold" :size="24" />
            </div>
            <div v-else class="w-[50px]" />

        </div>

        <Swiper
            :modules="modules"
            :initial-slide="props.gallery.start"
            :style="{
                '--swiper-navigation-color': '#fff',
            }"
            zoom
            loop
            @slide-change="onSlideChange"
        >
            <SwiperSlide v-for="(image, index) in props.gallery.images" :key="index">
                <div class="swiper-zoom-container">
                    <img :src="image.url" :alt="image.title" />
                </div>
            </SwiperSlide>
        </Swiper>

        <IonModal ref="modal" :trigger="modalUuid" :initial-breakpoint="1" :breakpoints="[0, 1]" class="ds-modal-bottom-sheet">
            <div class="py-6">
                <div>
                    <UISimple
                        title="Save image"
                        :icon="{ icon: 'image', icon_url: null, color: null }"
                        :right-icon="{ icon: 'caret-right', icon_url: null, color: null }"
                        :border="false"
                        @click="saveCurrentImage"
                    />
                </div>
            </div>
        </IonModal>

    </div>
</template>

<script setup>
    import { v4 as uuidv4 } from 'uuid'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Zoom, Navigation } from 'swiper'
    import { Capacitor } from '@capacitor/core'
    import { Media } from '@capacitor-community/media'

    import { statusBar } from '@/services'

    import 'swiper/css'

    import 'swiper/css/zoom'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import PhIcon from '@/components/app/PhIcon.vue'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'

    const modalUuid = ref(uuidv4())

    const props = defineProps({
        gallery: Object,
    })

    const deviceStore = useDeviceStore()
    const appStore = useAppStore()

    const { isIos } = storeToRefs(deviceStore)
    const { nativeIdentity } = storeToRefs(appStore)

    const { close } = useGallery()
    const { snackbar } = useSnackbars()

    const modules = [Zoom, Navigation]

    const currentIndex = ref(0)
    const mediaPluginAvailable = ref(Capacitor.isPluginAvailable('Media'))
    const modal = ref(null)

    const appName = computed(() => nativeIdentity.value?.appName ?? 'School App')

    // Android albums can't have special characters
    const safeAppName = computed(() => appName.value.replace(/[^a-zA-Z0-9 -]/g, ''))

    const showContextMenu = computed(() => {
        return mediaPluginAvailable.value || window.cypress
    })

    const onSlideChange = (swiper) => {
        currentIndex.value = swiper.realIndex
    }

    onBeforeMount(() => {
        statusBar.hide()
    })

    onBeforeUnmount(() => {
        statusBar.show()
    })

    const getAlbumIdentifier = async (search) => {
        return (await Media.getAlbums()).albums.find((album) => album.name === search)?.identifier
    }

    async function saveCurrentImage() {
        if (!mediaPluginAvailable.value) {
            return
        }

        const url = props.gallery.images[currentIndex.value].url

        // Android devices require an album identifier to save images
        let albumIdentifier = null
        if (!isIos.value) {
            albumIdentifier = await getAlbumIdentifier(safeAppName.value)
            if (!albumIdentifier) {
                await Media.createAlbum({ name: safeAppName.value })
                albumIdentifier = await getAlbumIdentifier(safeAppName.value)
            }
        }

        Media.savePhoto({ path: url, albumIdentifier })
            .then(() => {
                snackbar({
                    message: 'Image saved',
                    type: 'success',
                })
            })
            .catch((e) => {
                console.error('error', e)
                snackbar({
                    message: 'Error saving image',
                    type: 'error',
                })
            }).finally(() => {
                modal.value.$el.dismiss()
            })
    }
</script>

<style>
    .swiper {
        width: 100%;
        height: 100%;
    }
</style>
