import { defineStore } from 'pinia'
import { has, isEmpty } from 'lodash'
import mitt from 'mitt'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

import { analytics, api, preferences } from '@/services'
import { useKeygen } from '@/composables'
import authConstants from '@/constants/auth'
import { addCypressWindowListener } from '@/composables/cypress'

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            token: null,
            userKey: null,
            events: mitt(),
            registered: false,
            authRequirements: null,
            loginSuccessHandler: null,
            loginAbortHandler: null,
            browserAuthLoading: false,
            completionStarted: false,
        }
    },
    getters: {
        shouldUseAppViewerUrlAuthProcess() {
            if (Capacitor.isNativePlatform()) {
                return false
            }

            if (!window.Cypress) {
                return true
            }

            const initialSearchParams = new URLSearchParams(useAppStore().initialUrlSearch)
            return initialSearchParams.has('mock_app_viewer')
        },
    },
    actions: {
        async init() {
            const { generate } = useKeygen()

            this.token = await preferences.getItem('auth_token')
            this.userKey = await preferences.getItem('user_key')

            if (!this.userKey) {
                // generate a new key
                this.userKey = generate()

                // save it for next time
                preferences.setItem({
                    key: 'user_key',
                    value: this.userKey,
                })
            }

            this.registered = !!this.token
        },
        async setToken(token) {
            this.token = token
            await preferences.setItem({ key: 'auth_token', value: token })
        },
        async devicePayload() {
            const deviceStore = useDeviceStore()
            const appStore = useAppStore()

            const { device } = deviceStore

            return {
                external_id: device.identifier,
                platform: device.platform,
                timezone: await deviceStore.timezone(),
                locale: await deviceStore.locale(),
                os: device.operatingSystem,
                os_version: device.osVersion,
                manufacturer: device.manufacturer,
                model: device.model,
                is_virtual: device.isVirtual,
                web_view_version: device.webViewVersion,
                store_version: appStore.info.version,
                js_version: appStore.appVersion,
                native_version: appStore.nativeIdentity?.appVersion,
            }
        },
        async register() {
            if (this.registered) {
                return Promise.resolve()
            }

            const { appId } = useAppStore()
            const payload = await this.devicePayload()

            return api.post(`/${appId}/devices/register`, payload)
                .then(async ({ data }) => {
                    await this.setToken(data.token)
                })
                .catch((e) => {
                    console.log('Something wrong with register?')
                    return Promise.reject(e)
                })
        },
        async update() {
            const { appId } = useAppStore()
            const payload = await this.devicePayload()

            return api.put(`/${appId}/devices/update`, payload)
        },
        async migrate(payload) {
            const { appId } = useAppStore()

            return api.post(`/${appId}/devices/migrate`, payload)
        },
        async pushRegister(token) {
            const { appId } = useAppStore()

            console.log('registering push token with api', token)

            return api.post(`/${appId}/push/register`, {
                token,
            })
        },
        async pushUnregister() {
            const { appId } = useAppStore()

            console.log('unregistering push token with api')

            return api.post(`/${appId}/push/unregister`)
        },
        pushFormat(notification) {
            const { appId } = useAppStore()

            return api.post(`/${appId}/push/format`, notification)
                .then(({ data }) => {
                    return data
                })
        },
        refresh() {
            const { appId } = useAppStore()

            return api.post(`/${appId}/devices/refresh`)
                .then(({ data }) => {
                    this.setToken(data.token)
                })
                .catch(() => {
                    console.log('something wrong with refresh?')
                })
        },
        login({ url, username, password }) {
            return api.post(url, {
                username,
                password,
            })
                .then((response) => {
                    analytics.setRoles(response.data.roles ?? [])
                    this.events.emit('login', {
                        userSwitched: !isEmpty(response.headers[authConstants.LoginHeaders.userSwitched]),
                    })
                })
                .catch((error) => {
                    this.events.emit('error', error)
                    throw error
                })
        },
        setAuthRequirements(url) {
            this.authRequirements = url
        },
        setLoginSuccessHandler(handler) {
            this.loginSuccessHandler = handler
        },
        setLoginAbortHandler(handler) {
            this.loginAbortHandler = handler
        },
        logout() {
            console.log('Remove token')
            this.token = null
            preferences.removeItem('auth_token')
        },
        start({ url }) {
            this.completionStarted = false
            this.browserAuthLoading = true
            api.post(url)
                .then(({ data }) => {
                    if (!has(data, 'url')) {
                        throw new Error('Missing URL from start auth request')
                    }
                    const url = data.url

                    const handleBrowserFinished = () => {
                        if (this.completionStarted) {
                            return
                        }
                        this.browserAuthLoading = false
                        this.events.emit('abort')
                        Browser.removeAllListeners()
                    }

                    Browser.addListener('browserFinished', handleBrowserFinished)
                    addCypressWindowListener('Browser', 'browserFinished', handleBrowserFinished)

                    Browser.open({ url })
                })
                .catch((error) => {
                    this.browserAuthLoading = false
                    this.events.emit('error', error)
                })
        },
        complete({ url }) {
            this.completionStarted = true
            // Remove browser finished event etc.
            Browser.removeAllListeners()
            // Close the in-app browser
            Browser.close()

            if (!url) {
                this.browserAuthLoading = false
                this.completionStarted = false
                throw new Error('Missing complete URL')
            }

            return api.post(url)
                .then((response) => {
                    analytics.setRoles(response.data.roles ?? [])
                    this.events.emit('login', {
                        userSwitched: !isEmpty(response.headers[authConstants.LoginHeaders.userSwitched]),
                    })
                })
                .catch((error) => {
                    this.browserAuthLoading = false
                    this.completionStarted = false
                    this.events.emit('error', error)
                    throw new Error('Could not complete auth', error)
                })
        },
    },
})
